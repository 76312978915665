import dayjs from 'dayjs';

export const generateEmailContent = (demande) => {
    return `
    <div style="font-family: Arial, sans-serif; margin: auto; border: 1px solid #ccc; border-radius: 8px; overflow: hidden; margin-top: 20px; margin-bottom: 20px;">
        <div style="background-color: #f0f0f0; padding: 16px; text-align: center;">
            <h2 style="margin: 0;">Détails de la demande</h2>
        </div>
        <div style="padding: 16px;">
            <div style="display: flex; justify-content: space-between; margin-bottom: 16px;">
                <div style="flex: 1; margin-right: 8px; background-color: #e0e0e0; padding: 8px; border-radius: 4px;">
                    <h3 style="margin-top: 0;">Informations sur l'animal</h3>
                    <p><strong>Nom de l'animal:</strong> ${demande.animalName}</p>
                    <p><strong>Type de l'animal:</strong> ${demande.animalType}</p>
                    <p><strong>Race de l'animal:</strong> ${demande.animalRace}</p>
                    <p><strong>Poids de l'animal:</strong> ${demande.animalWeight ? demande.animalWeight + ' kg' : ''}</p>
                </div>
                ${demande.ownerFirstName ? `
                <div style="flex: 1; margin-left: 8px; background-color: #e0e0e0; padding: 8px; border-radius: 4px;">
                    <h3 style="margin-top: 0;">Informations sur le propriétaire</h3>
                    <p><strong>Prénom:</strong> ${demande.ownerFirstName}</p>
                    <p><strong>Nom:</strong> ${demande.ownerLastName}</p>
                    <p><strong>Adresse:</strong> ${demande.ownerAddress}</p>
                    <p><strong>Code postal:</strong> ${demande.ownerPostalCode}</p>
                    <p><strong>Ville:</strong> ${demande.ownerCity}</p>
                    <p><strong>Téléphone:</strong> ${demande.ownerPhone}</p>
                </div>
                ` : ''}
            </div>
            <div style="display: flex; justify-content: space-between; margin-bottom: 16px;">
                <div style="flex: 1; margin-right: 8px; background-color: #e0e0e0; padding: 8px; border-radius: 4px;">
                    <h3 style="margin-top: 0;">Informations sur la crémation</h3>
                    <p><strong>Type de crémation:</strong> ${demande.cremationType}</p>
                    <p><strong>Urne:</strong> ${demande.urne ? 'Oui' : 'Non'}</p>
                    ${demande.urne ? `<p><strong>Type d'urne:</strong> ${demande.urneType}</p>` : ''}
                    <p><strong>Pierre éternelle:</strong> ${demande.pierreEternelle ? 'Oui' : 'Non'}</p>
                    ${demande.pierreEternelle ? `<p><strong>Nombre de la pierre éternelle:</strong> ${demande.pierreEternelleValue}</p>` : ''}
                    ${demande.pierreEternelle ? `<p><strong>Type de pierre éternelle:</strong> ${demande.pierreEternelleType}</p>` : ''}
                    ${demande.souvenir ? `<p><strong>Souvenir support empreintes/poils:</strong> ${demande.souvenir ? 'Oui' : 'Non'}</p>` : ''}
                    ${demande.remarques ? `<p><strong>Remarques:</strong> ${demande.remarques}</p>` : ''}
                </div>
                <div style="flex: 1; margin-left: 8px; background-color: #e0e0e0; padding: 8px; border-radius: 4px;">
                    <h3 style="margin-top: 0;">Informations</h3>
                    <p><strong>Statut:</strong> ${demande.statut}</p>
                    <p><strong>Date de ramassage:</strong> ${demande.ramassageDate ? dayjs(demande.ramassageDate).format('DD/MM/YYYY') : ''}</p>
                    <p><strong>Heure de ramassage:</strong> ${demande.ramassageTime}</p>
                    <p><strong>Date de création:</strong> ${new Date(demande.created).toLocaleDateString('fr-FR')} à ${new Date(demande.created).toLocaleTimeString('fr-FR')}</p>
                </div>
            </div>
        </div>
    </div>
    <p style="margin: 0;">Cordialement,<br/>L'équipe du Crematorium Animalier de Picardie</p>
    `;
};