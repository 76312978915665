import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from './firebase';
import { Container, Grid, Card, CardContent, Typography, CircularProgress, Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Chiffres = () => {
  const [demandes, setDemandes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'demandes'), snapshot => {
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDemandes(data);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  // Calcul des statistiques
  const totalCremations = demandes.filter(d => d.statut === 'Crémation effectuée' || d.statut === 'Urne restituée').length;
  const cremationCollective = demandes.filter(d => d.cremationType === 'Crémation collective' && (d.statut === 'Crémation effectuée' || d.statut === 'Urne restituée')).length;
  const cremationIndivAccomp = demandes.filter(d => d.cremationType === 'Crémation individuelle accompagnée' && (d.statut === 'Crémation effectuée' || d.statut === 'Urne restituée')).length;
  const cremationIndivNonAccomp = demandes.filter(d => d.cremationType === 'Crémation individuelle non accompagnée' && (d.statut === 'Crémation effectuée' || d.statut === 'Urne restituée')).length;

  // Nombre de demandes avec pierreEternelle == true / souvenir == true
  const pierresVendues = demandes.filter(d => (d.statut === 'Crémation effectuée' || d.statut === 'Urne restituée') && d.pierreEternelle).length;
  const souvenirsVendus = demandes.filter(d => (d.statut === 'Crémation effectuée' || d.statut === 'Urne restituée') && d.souvenir).length;

  // Regrouper par mois le nombre de crémations
  const monthlyData = {};
  demandes.forEach(d => {
    if ((d.statut === 'Crémation effectuée' || d.statut === 'Urne restituée') && d.cremaDate) {
      const date = new Date(d.cremaDate);
      const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
      if (!monthlyData[monthKey]) {
        monthlyData[monthKey] = { collective: 0, indivAccomp: 0, indivNonAccomp: 0 };
      }
      if (d.cremationType === 'Crémation collective') {
        monthlyData[monthKey].collective += 1;
      } else if (d.cremationType === 'Crémation individuelle accompagnée') {
        monthlyData[monthKey].indivAccomp += 1;
      } else if (d.cremationType === 'Crémation individuelle non accompagnée') {
        monthlyData[monthKey].indivNonAccomp += 1;
      }
    }
  });

  // Fonction pour convertir une chaîne de mois en objet Date
  const parseMonthYear = (monthYearStr) => {
    const [month, year] = monthYearStr.split(' ');
    const monthNames = [
      'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
      'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
    ];
    const monthIndex = monthNames.indexOf(month.toLowerCase());
    return new Date(year, monthIndex);
  };

  // Extraire les labels et datasets pour l’histogramme
  const sortedMonths = Object.keys(monthlyData).sort((a, b) => parseMonthYear(a) - parseMonthYear(b));
  const dataCollective = sortedMonths.map(m => monthlyData[m].collective);
  const dataIndivAccomp = sortedMonths.map(m => monthlyData[m].indivAccomp);
  const dataIndivNonAccomp = sortedMonths.map(m => monthlyData[m].indivNonAccomp);

  // Préparer les données pour le Bar chart empilé
  const barChartData = {
    labels: sortedMonths,
    datasets: [
      {
        label: 'Crémations collectives',
        data: dataCollective,
        backgroundColor: '#F1C40F'
      },
      {
        label: 'Crémations indiv. accompagnées',
        data: dataIndivAccomp,
        backgroundColor: '#1b5e20'
      },
      {
        label: 'Crémations indiv. non accompagnées',
        data: dataIndivNonAccomp,
        backgroundColor: '#7B241C'
      }
    ]
  };

  // Options pour le graphique en barres empilées
  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom'
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          maxRotation: 0,
          minRotation: 0
        }
      },
      y: {
        stacked: true
      }
    },
    barThickness: 80 // Limite la largeur des barres
  };

  // Préparer les data pour le “pierre éternelle” & “souvenir”
  const barChartDataPierres = {
    labels: ['Pierre éternelle', 'Souvenir'],
    datasets: [
      {
        label: 'Ventes',
        data: [pierresVendues, souvenirsVendus],
        backgroundColor: ['#7B241C', '#1B2631']
      }
    ]
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container sx={{ paddingY: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Statistiques
      </Typography>
      <Grid container spacing={2}>
        {/* Cartes du haut */}
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total crémations</Typography>
              <Typography variant="h4">{totalCremations}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Crémations collectives</Typography>
              <Typography variant="h4">{cremationCollective}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Indiv. accompagnées</Typography>
              <Typography variant="h4">{cremationIndivAccomp}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Indiv. non accompagnées</Typography>
              <Typography variant="h4">{cremationIndivNonAccomp}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Nombre de crémations par mois
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 2 }}>
          <Bar data={barChartData} options={barChartOptions} />
        </Box>
      </Box>

      {/* <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Pierres éternelles et souvenirs vendus
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 2 }}>
          <Bar data={barChartDataPierres} options={{ responsive: true, plugins: { legend: { position: 'bottom' }}}} />
        </Box>
      </Box> */}
    </Container>
  );
};

export default Chiffres; 