import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, doc, updateDoc, addDoc, deleteDoc, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, Container, Typography, Grid, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, Box, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';
import { useAlert } from './AlertContext'; // Remplacez par la bibliothèque d'alertes que vous utilisez
import { auth, db } from './firebase';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { localeText } from './config/localeText';
import CloseIcon from '@mui/icons-material/Close';

export const GetFournitures = (userRole, onUpdateFournitures) => {
    const [fournitures, setFournitures] = useState([]);
    const [viewFourniture, setViewFourniture] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(true);
    const { showAlert } = useAlert();

    const handleUpdateFournitures = () => {
        fetchFournitures();
    };

    const fetchFournitures = () => {
        setLoading(true);
        const fournituresCollectionRef = collection(db, "fournitures");

        const unsubscribe = onSnapshot(fournituresCollectionRef, (snapshot) => {
            const data = snapshot.docs.map(doc => {
                const docData = doc.data();
                return {
                    id: doc.id,
                    ...docData,
                    index: parseInt(docData.index) + 1
                };
            });
            data.sort((a, b) => (a.index) - (b.index));
            setFournitures(data);
            setLoading(false);
        }, (error) => {
            console.error("Erreur lors de la récupération des fournitures:", error);
            setLoading(false);
        });

        return unsubscribe;
    };

    useEffect(() => {
        const unsubscribe = fetchFournitures();
        return () => unsubscribe && unsubscribe();
    }, []);

    const handleRowClick = (fourniture) => {
        setViewFourniture(fourniture);
        setOpenAddModal(true);
    };

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        setViewFourniture(null);
    };
    const handleDeleteFourniture = async (fourniture) => {
        if (window.confirm(`Êtes-vous sûr de vouloir supprimer la fourniture "${fourniture.title}" ?`)) {
            try {
                await deleteDoc(doc(db, "fournitures", fourniture.id));
                showAlert("Fourniture supprimée avec succès !", 'success');
            } catch (error) {
                console.error("Erreur lors de la suppression de la fourniture :", error);
                showAlert("Erreur lors de la suppression.", 'error');
            }
        }
    };


    const columns = [
        {
            field: 'index',
            headerName: 'Classement',
            flex: 0.5
        },
        { field: 'title', headerName: 'Titre', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            renderCell: (params) => (
                <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteFourniture(params.row); }}>
                    <DeleteIcon />
                </IconButton>
            )
        }
    ];

    return (
        <Container maxWidth="lg">
            <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Fournitures</Typography>
            {isMobile ? (
                <Grid container spacing={3} justifyContent="center" sx={{ paddingBottom: 20, overflow: 'auto' }}>
                    {fournitures.map((fourniture) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={fourniture.id}>
                            <Card variant="outlined" sx={{ height: '100%', padding: 0, paddingBottom: 1.5 }} onClick={() => handleRowClick(fourniture)} style={{ cursor: 'pointer' }}>
                                <CardContent>
                                    <Box 
                                        sx={{ 
                                            display: 'flex', 
                                            flexDirection: 'column',
                                            height: '100%',
                                            position: 'relative'
                                        }}
                                    >
                                        <Box 
                                            sx={{ 
                                                position: 'absolute',
                                                top: -8,
                                                left: -8,
                                                backgroundColor: '#EB984E',
                                                color: 'white',
                                                borderRadius: '50%',
                                                width: 30,
                                                height: 30,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {fourniture.index}
                                        </Box>
                                        <Box 
                                            sx={{ 
                                                display: 'flex', 
                                                justifyContent: 'space-between',
                                                alignItems: 'flex-start',
                                                mt: 1
                                            }}
                                        >
                                            <Typography 
                                                variant="h6" 
                                                sx={{ 
                                                    fontWeight: 600, 
                                                    fontSize: "100%",
                                                    ml: 4,
                                                    flex: 1
                                                }}
                                            >
                                                {fourniture.title}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    height: '100%',
                                                    width: '1px',
                                                    backgroundColor: '#E0E0E0',
                                                    mx: 1
                                                }}
                                            />
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteFourniture(fourniture);
                                                }}
                                                size="small"
                                                sx={{ ml: 1 }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <>
                    {/* <IconButton
                    color="primary"
                    aria-label="add"
                    onClick={() => setOpenAddModal(true)}
                // sx={{ position: 'fixed', bottom: 16, right: 16 }}
                >
                    <AddIcon />
                </IconButton> */}
                    <DataGrid
                        rows={fournitures}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        onRowClick={(params) => handleRowClick(params.row)}
                        localeText={localeText}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#7cb1e2', // Couleur de fond pour les en-têtes de colonnes
                            },
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: '#7cb1e2', // Couleur de fond pour chaque en-tête de colonne
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                color: 'white', // Couleur du texte pour les en-têtes de colonnes
                            },
                            '& .MuiDataGrid-row:nth-of-type(odd)': {
                                backgroundColor: '#ffffff', // Couleur de fond pour les lignes paires
                            },
                            '& .MuiDataGrid-row:nth-of-type(even)': {
                                backgroundColor: '#7cb1e2', // Couleur de fond pour les lignes impaires
                                color: 'white',
                            },
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            '& .MuiDataGrid-cell': {
                                cursor: 'pointer',
                            },
                            '& .MuiDataGrid-row.Mui-selected': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-row:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                        }}
                    />
                </>
            )}

            <Fab
                color="primary"
                aria-label="add"
                onClick={() => setOpenAddModal(true)}
                sx={{
                    position: 'fixed',
                    bottom: 80,
                    right: 30,
                    backgroundColor: "#1976D2"
                }}
            >
                <AddIcon />
            </Fab>
            <AddFournitures fournitureProp={viewFourniture} userRole={userRole} onClose={handleCloseAddModal} open={openAddModal} onUpdateFournitures={handleUpdateFournitures} />
        </Container>
    );
};

export const AddFournitures = ({ fournitureProp, userRole, onClose, open, onUpdateFournitures }) => {
    const { showAlert } = useAlert();
    const [fourniture, setFourniture] = useState({
        title: '',
        id: null,
        index: ''
    });
    const [totalFournitures, setTotalFournitures] = useState(0);
    const [maxIndex, setMaxIndex] = useState(0);

    // Fonction pour réinitialiser le formulaire
    const resetForm = () => {
        setFourniture({
            title: '',
            id: null,
            index: maxIndex + 2
        });
    };

    // Fonction pour gérer la fermeture
    const handleClose = () => {
        resetForm();
        onClose();
    };

    useEffect(() => {
        // Récupérer le nombre total de fournitures et l'index maximum
        const fournituresRef = collection(db, "fournitures");
        const unsubscribe = onSnapshot(fournituresRef, (snapshot) => {
            setTotalFournitures(snapshot.docs.length);

            // Trouver l'index maximum
            let max = 0;
            snapshot.docs.forEach(doc => {
                const index = parseInt(doc.data().index || 0);
                if (index > max) max = index;
            });
            setMaxIndex(max);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (fournitureProp) {
            setFourniture(fournitureProp);
        } else if (open) { // Seulement si le modal est ouvert
            setFourniture({
                title: '',
                id: null,
                index: maxIndex + 2
            });
        }
    }, [fournitureProp, maxIndex, open]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFourniture(prev => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const newIndex = parseInt(fourniture.index) - 1;
            const oldIndex = fournitureProp ? parseInt(fournitureProp.index) - 1 : null;

            // Si c'est une modification et que l'index a changé
            if (fourniture.id && oldIndex !== newIndex) {
                // Chercher la fourniture qui a l'index que nous voulons utiliser
                const fournituresRef = collection(db, "fournitures");
                const snapshot = await getDocs(fournituresRef);
                const fournitureWithTargetIndex = snapshot.docs.find(doc => {
                    const data = doc.data();
                    return parseInt(data.index) === newIndex && doc.id !== fourniture.id;
                });

                // Si on trouve une fourniture avec cet index, on échange les index
                if (fournitureWithTargetIndex) {
                    await updateDoc(doc(db, "fournitures", fournitureWithTargetIndex.id), {
                        ...fournitureWithTargetIndex.data(),
                        index: oldIndex
                    });
                }
            }

            const saveData = {
                ...fourniture,
                index: newIndex
            };

            if (fourniture.id) {
                await updateDoc(doc(db, "fournitures", fourniture.id), saveData);
                showAlert("Fourniture mise à jour avec succès !", 'success');
            } else {
                const { id, ...data } = saveData;
                await addDoc(collection(db, "fournitures"), data);
                showAlert("Nouvelle fourniture créée avec succès !", 'success');
            }
            if (typeof onUpdateFournitures === 'function') {
                onUpdateFournitures();
            }
            onClose();
        } catch (error) {
            console.error("Erreur lors de la soumission de la fourniture :", error);
            showAlert("Erreur lors de la soumission.", 'error');
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>
                {fourniture.id ? "Modifier la fourniture" : "Nouvelle fourniture"}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Container>
                    <Grid item xs={12}>
                        <FormControl fullWidth sx={{ marginLeft: 0, marginRight: 0, marginTop: 1, marginBottom: 1 }}>
                            <Box component="form" onSubmit={handleFormSubmit}>
                                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <InputLabel id="index-label">Classement</InputLabel>
                                    <Select
                                        labelId="index-label"
                                        name="index"
                                        value={fourniture.index}
                                        onChange={handleChange}
                                        label="Classement"
                                        required
                                    >
                                        {[...Array(totalFournitures + 1)].map((_, i) => (
                                            <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <TextField
                                        name="title"
                                        label="Titre"
                                        value={fourniture.title}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </FormControl>
                                <Button type='submit' variant="contained" color="success">
                                    Enregistrer
                                </Button>
                            </Box>
                        </FormControl>
                    </Grid>
                </Container>
            </DialogContent>
        </Dialog>
    );
};
